import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import styled from 'styled-components';
import {generateMatchDayTitle} from 'app/helpers/actionsHelpers';
import {Event} from '@mui/icons-material';
import {MatchCardFooter} from 'app/components/matchCalendar/MatchCardFooter';
import SwipeableViewsStepper from 'app/components/swipeableViews/SwipeableViewsStepper';
import {chunkArray} from 'app/helpers/chunkArray';
import MatchRowTeaser from 'app/components/matchRow/teaser/MatchRowTeaser';
import MatchModel from 'app/models/match/MatchModel';

const HEADER_HEIGHT = 53;
const FOOTER_HEIGHT = 46;

const Container = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 0.5rem;

    ${props => props.theme.desktopLayout`
      overflow: auto;
      max-height: ${399 - HEADER_HEIGHT - FOOTER_HEIGHT}px;
      min-height: ${183 - HEADER_HEIGHT - FOOTER_HEIGHT}px;
  `}
  }
`;

const Wrapper = styled.div`
  ${props => props.theme.desktopLayout`
      max-height: inherit;
      min-height: inherit;
      overflow: hidden;
  `}
`;

const MatchDayResultActivity = ({activity}) => {
  const {matches} = activity.activity;
  const elements = matches.map(match => <MatchRowTeaser match={new MatchModel(match)} />);

  let title = activity.activity?.competition?.name ?? activity.activity.competitionName;
  const matchDayTitle = generateMatchDayTitle(activity.activity.round);
  if (matchDayTitle) {
    title += `, ${matchDayTitle}`;
  }

  let footerComp;
  if (activity.activity?.competition) {
    const competition = {
      active: activity.activity.season.active,
      ...activity.activity.competition,
      season: activity.activity.season,
    };

    const isLeague = activity.activity.round.type === 'league';

    footerComp = <MatchCardFooter competition={competition} isLeague={isLeague} />;
  }

  const steps = chunkArray(elements, 8);
  const content =
    steps.length > 1 ? (
      <SwipeableViewsStepper
        viewRender={({index}) => <Container key={`${title}-${index}`}>{steps[index]}</Container>}
        views={steps}
      />
    ) : (
      <Container>{elements}</Container>
    );

  return (
    <Wrapper>
      <ActivityHeader
        title='Spieltag'
        subtitle={title}
        icon={<Event style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.orangeSoda}
        timestamp={activity.timestamp}
        category='match_day_results'
      />
      {content}
      {footerComp}
    </Wrapper>
  );
};

MatchDayResultActivity.propTypes = {
  activity: object.isRequired,
};

export default MatchDayResultActivity;
