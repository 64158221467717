import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';

const Kickoff = styled(FlexContainer)`
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${SIZES['56']};
  height: ${SIZES['30']};
`;

const MatchStatus = styled(Typography)`
  &&& {
    line-height: 1.4;
    color: ${COLORS.black};
  }
`;

const LiveTypography = styled(Typography)`
  &&& {
    line-height: 1.25;
  }
`;

interface MatchRowTeaserKickoffProps {
  kickoff: string;
  live: boolean;
}

export default function MatchRowTeaserKickoff({kickoff, live}: MatchRowTeaserKickoffProps) {
  return (
    <Kickoff>
      <MatchStatus variant='caption3'>{kickoff}</MatchStatus>
      {live ? <LiveTypography variant='caption1'>live</LiveTypography> : null}
    </Kickoff>
  );
}
