import {MatchResultModel} from 'app/models/match/MatchResultModel';
import {MatchTeamModel} from 'app/models/match/MatchTeamModel';
import MatchLink from 'app/models/match/MatchLink';
import {MatchModelInterface} from 'app/models/match/MatchModelInterface';

export default class MatchModel {
  readonly slug: string;
  readonly result: MatchResultModel;
  readonly homeTeam: MatchTeamModel;
  readonly awayTeam: MatchTeamModel;
  private readonly link: MatchLink;

  constructor(match: MatchModelInterface) {
    this.slug = match.slug;
    this.result = new MatchResultModel(match);
    this.homeTeam = new MatchTeamModel(match.homeTeam);
    this.awayTeam = new MatchTeamModel(match.awayTeam);
    this.link = new MatchLink(match);
  }

  getLink(): string {
    return this.link.getLink();
  }
}
