import React from 'react';
import {LinkOnComponent} from 'app/components/links/link';
import MatchRowTeaserTeamImage from 'app/components/matchRow/teaser/team/MatchRowTeaserTeamImage';
import MatchRowTeaserResult from 'app/components/matchRow/teaser/MatchRowTeaserResult';
import MatchModel from 'app/models/match/MatchModel';
import {TeamName, TeamNames, TeamResult, Wrapper} from 'app/components/matchRow/teaser/teaserStyles';

interface MatchRowTeaserProps {
  match: MatchModel;
}

export default function MatchRowTeaser({match}: MatchRowTeaserProps) {
  return (
    <Wrapper as={LinkOnComponent} to={match.getLink()}>
      <TeamResult>
        <MatchRowTeaserTeamImage team={match.homeTeam} />
        <MatchRowTeaserResult matchResult={match.result} />
        <MatchRowTeaserTeamImage team={match.awayTeam} />
      </TeamResult>
      <TeamNames>
        <TeamName variant='caption1'>{match.homeTeam.getNameWithMaxLength('middle', 12)}</TeamName>
        <TeamName variant='caption1'>{match.awayTeam.getNameWithMaxLength('middle', 12)}</TeamName>
      </TeamNames>
    </Wrapper>
  );
}
