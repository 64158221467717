import React from 'react';
import {Button, MobileStepper} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import {virtualize} from 'react-swipeable-views-utils';
import {StepperPlaceholder} from 'app/components/styles/swipeableViews/StepperPlaceholder';
import {useSwipeableViewsActiveStep} from 'app/components/swipeableViews/useSwipeableViewsActiveStep';
const VirtualizeSwipeableViews = virtualize(SwipeableViews);

interface SwipeableViewsStepperProps {
  views: any[];
  viewRender: (obj: {index: number}) => React.ReactElement;
  customStyles?: React.CSSProperties;
  className?: string;
  hideStepperWithoutPages?: boolean;
}

export default function SwipeableViewsStepper({
  views,
  viewRender,
  customStyles,
  className,
  hideStepperWithoutPages,
}: SwipeableViewsStepperProps) {
  const {activeStep, maxSteps, handleNext, handleBack, handleIndexChange} = useSwipeableViewsActiveStep(views);

  return (
    <div className={className}>
      <VirtualizeSwipeableViews
        index={activeStep}
        onChangeIndex={handleIndexChange}
        overscanSlideBefore={activeStep > 0 ? 1 : 0}
        overscanSlideAfter={activeStep < maxSteps ? 1 : 0}
        slideCount={views.length}
        slideRenderer={viewRender}
        style={customStyles}
      />
      {views.length > 1 ? (
        <MobileStepper
          steps={views.length}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button size='small' onClick={handleNext} disabled={activeStep >= maxSteps}>
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStep <= 0}>
              <KeyboardArrowLeft />
            </Button>
          }
        />
      ) : hideStepperWithoutPages ? null : (
        <StepperPlaceholder />
      )}
    </div>
  );
}
