import {SIZES, Typography} from '@fupa/fupa-uikit';
import MatchResult from 'app/components/matchRow/MatchResult';
import React from 'react';
import {MatchResultModel} from 'app/models/match/MatchResultModel';
import MatchRowTeaserKickoff from 'app/components/matchRow/teaser/MatchRowTeaserKickoff';
import styled from 'styled-components';
import MatchResultTeaserResultHint from 'app/components/matchRow/teaser/MatchResultTeaserResultHint';

const Wrapper = styled.div`
  position: relative;
  width: ${SIZES['46']};
  height: ${SIZES['30']};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface MatchRowTeaserResultProps {
  matchResult: MatchResultModel;
  color?: string;
}

export default function MatchRowTeaserResult({matchResult, color}: MatchRowTeaserResultProps) {
  if (matchResult.isCancelled()) {
    return (
      <Wrapper>
        <Typography variant={'caption1'}>abgesagt</Typography>
      </Wrapper>
    );
  } else if (!matchResult.hasVerdict() && (matchResult.isInFuture() || matchResult.isRunningWithoutTicker())) {
    return (
      <Wrapper>
        <MatchRowTeaserKickoff kickoff={matchResult.formatKickoff()} live={matchResult.hasLiveticker()} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <MatchResult matchResult={matchResult} allowLive={true} color={color} />
      <MatchResultTeaserResultHint flags={matchResult.result.flags} />
    </Wrapper>
  );
}
