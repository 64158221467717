import {ImageInterface} from 'app/types/ImageInterface';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {ImageEntity} from '@fupa/fupa-uikit';

interface ImageData {
  alt: string;
  entity: ImageEntity;
  src: ImageInterface;
  fit: boolean;
}

export interface MatchTeamModelInterface {
  readonly name?: {
    readonly full: string;
    readonly middle: string;
    readonly short: string;
  };
  readonly placeholder?: string | null;
  readonly level: number;
  readonly image?: ImageInterface;
}

export class MatchTeamModel {
  readonly team: MatchTeamModelInterface;

  constructor(result: MatchTeamModelInterface) {
    this.team = result;
  }

  getImageAlt(): string {
    return this.team.name?.full || this.team.name?.middle || this.team.placeholder || '';
  }

  formatLevel(): string | null {
    return this.team.level > 1 ? convertToRoman(this.team.level) : null;
  }

  getImageData(): ImageData | null {
    if (!this.team.image) {
      return null;
    }
    return {
      alt: this.getImageAlt(),
      entity: ImageEntity.Emblem,
      src: this.team.image,
      fit: true,
    };
  }

  getNameWithMaxLength(defaultNameLength: 'full' | 'middle' | 'short', maxLength?: number): string {
    const name = this.team.name?.[defaultNameLength] || this.team.placeholder || '';
    if (maxLength && name.length > maxLength) {
      return `${name.substring(0, maxLength - 1)}.`;
    }
    return name;
  }
}
