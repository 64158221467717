import styled from 'styled-components';
import {COLORS, FONTS, SIZES, Typography} from '@fupa/fupa-uikit';

const MatchRowTeaserWrapper = styled.div`
  min-height: 3.75rem;
  max-height: 3.75rem;
  width: 10rem;
  @media only screen and (max-width: 349px) {
    width: 9rem;
  }
`;

const Wrapper = styled(MatchRowTeaserWrapper)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.lighterGrey};
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
`;

const TeamResult = styled.div`
  display: flex;
  gap: ${SIZES[16]};
  padding-top: 2px;
`;

const TeamNames = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
`;

const TeamName = styled(Typography)`
  &&& {
    font-size: ${FONTS['10']};
    white-space: nowrap;
    padding-top: 2px;
  }
`;

export {MatchRowTeaserWrapper, Wrapper, TeamResult, TeamNames, TeamName};
