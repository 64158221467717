import React from 'react';
import {Emblem} from 'app/components/icons';
import {COLORS, Image, SIZES, TextBadge} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {MatchTeamModel} from 'app/models/match/MatchTeamModel';

const EmblemContainer = styled.div`
  position: relative;
  width: ${SIZES['32']};
  height: ${SIZES['32']};
`;

const SCTextBadge = styled(TextBadge)`
  &&& {
    right: 0;
    width: ${SIZES['10']};
    height: ${SIZES['10']};
    font-size: ${SIZES['8']};
  }
`;

interface MatchRowTeaserTeamImageProps {
  team: MatchTeamModel;
}

export default function MatchRowTeaserTeamImage({team}: MatchRowTeaserTeamImageProps) {
  const level = team.formatLevel();
  const imageData = team.getImageData();

  const emblem = imageData ? (
    <Image {...imageData} width={32} height={32} />
  ) : (
    <Emblem style={{color: COLORS.lightGrey, fontSize: 32}} />
  );

  return (
    <EmblemContainer>
      {level ? <SCTextBadge value={level} position={1} /> : null}
      {emblem}
    </EmblemContainer>
  );
}
