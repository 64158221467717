import React from 'react';
import styled from 'styled-components';
import {Typography} from '@fupa/fupa-uikit';
import {MatchFlag, MatchFlags} from 'app/types/match/MatchFlags';

const FinishedInfo = styled(Typography)`
  &&& {
    position: absolute;
    bottom: -0.5rem;
  }
`;

const FlagLabel = {
  [MatchFlag.Penalty]: 'n.E.',
  [MatchFlag.Overtime]: 'n.V.',
  [MatchFlag.Verdict]: 'Urteil',
  [MatchFlag.Aborted]: 'Abbr.',
} as const;

interface MatchResultTeaserResultHintProps {
  flags: MatchFlags;
}

export default function MatchResultTeaserResultHint({flags}: MatchResultTeaserResultHintProps) {
  if (!flags || !Object.keys(FlagLabel).some(flag => flags.includes(flag as MatchFlag))) {
    return null;
  }

  const flagKey = Object.keys(FlagLabel).find(flag => flags.includes(flag as MatchFlag)) as MatchFlag;

  return <FinishedInfo variant={'caption1'}>{FlagLabel[flagKey]}</FinishedInfo>;
}
